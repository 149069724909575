import {
  Button,
  MenuItem,
  Stack,
  Typography,
  styled,
  Grid,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./PaymentTable";
import PaymentsIcon from "@mui/icons-material/Payments";
import CustomDialogue from "../reuseableComp/Modal";
import { CustomField } from "../client";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Logo from "../../assets/erorex-Logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import ReactToPrint from "react-to-print";

import {
  getBanks,
  getClient,
  getCustomerLedgers,
  getData,
  getPayment,
  getSupplier,
  getSupplierLedgers,
  getSupplierPayment,
  savePaymentNew,
  saveSupplierPayment,
  updateSpecificPayment,
  updateSupplierPayment,
} from "../../apiservices";
import { toast } from "react-toastify";
import Search from "../searchBar";
import moment from "moment";
import ClientInfo from "../ClientInfo";
export const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#05ae97",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Typo = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
  color: "#05ae97",
});
export const Lay = styled(Stack)({
  // borderRadius: "8px",
  padding: 15,
  border: "1px solid #9F9E9E",
});
const SupplierPayment = () => {
  const theme = useTheme();
  const componentRef = useRef();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [accountInfo, setAccountInfo] = useState("")
  const [receivedBy, setRecievedBy] = useState("")
  const [state, setState] = useState({
    openModal: false,
    viewPayment: false,
    clientData: [],
    clientDataForAutoComplete: [],
    banksList: [],
    selectedMode: "",
    selectedClient: "",
    selectedBank: "",
    amount: 0,
    viewLedger: false,
    payments: {},
    paymentData: [],
    accNum: "",
    accId: 0,
    searchData: [],
    editedData: {},
    currentBalance: 0,
    accountsArr: [],
    uniBanksArr: [],
    selectedAcc: {},
    prevBalance: 0,
    check: false,
    updatedData: {},
  });
  const getSuppliersData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {

    };
    let res = await getSupplierLedgers();
    const arr = []
    if (res.success) {
      res.data.forEach((item) => {
        arr.push({
          clientName: `${item.name} (Balance: ${parseFloat(item.opening_balance) + parseFloat(item.debit) - parseFloat(item.credit)})`,
          clientId: item.id
        })
      });

      setState((prev) => ({ ...prev, clientData: res.data, clientDataForAutoComplete: arr }));
    }
  };

  const getBanksList = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {

    };
    let res = await getBanks(obj);
    if (res.success) {
      let arr = [];
      res.data.map((item) => arr.push(item.bank_name));
      const accInfo = res.data && res.data.length > 0 && res.data.filter((x) => x.id == state.payments.acc_id)[0]
      if (accInfo) {
        setAccountInfo(`${accInfo.account_title} [${accInfo.account_number}]`)
      }
      const arrayWithDuplicates = arr;
      const uniqueArray = [...new Set(arrayWithDuplicates)];
      setState((prev) => ({
        ...prev,
        banksList: res.data,
        uniBanksArr: uniqueArray,
      }));
    }
  };

  useEffect(() => {
    getSuppliersData();
  }, []);

  useEffect(() => {
    getPayments();
  }, [state.clientData])

  useEffect(() => {
    getBanksList();
  }, [state.payments])


  useEffect(() => {
    getUsers();
  }, [state.payments])

  const getUsers = async () => {
    const data = {
      table: "users"
    }
    const res = await getData(data)
    if (res.success) {
      const user = res.data && res.data.length && res.data.filter((x) => x.id == state.payments.added_by)[0]
      if (user) {
        setRecievedBy(`${user.fname} ${user.lname}`)
      }
    }
  }

  const getPayments = async () => {
    let obj = {};
    let res = await getSupplierPayment(obj);

    if (res.success) {
      res.data.map((x) => {
        if (x.id.length == 1) {
          x.show_id = `Payment#00${x.id}`
        }
        else if (x.id.length == 2) {
          x.show_id = `Payment#0${x.id}`
        }
        else {
          x.show_id = `Payment#${x.id}`
        }
        if (state.clientData && state.clientData.length) {
          x.supplier_name = state.clientData.filter(client => client.id === x.supplier_id)[0].name
        }
      })
      setState((prev) => ({
        ...prev,
        paymentData: res.data.sort((a, b) => b.id - a.id)
      }));
    }
    else {
      setState((prev) => ({
        ...prev,
        paymentData: [],
      }));
    }
  };
  const handleModal = (data = "") => {
    if (data.amount) {
      setState((prev) => ({ ...prev, check: true }));
    }
    const sBank = state.banksList && state.banksList.length > 0 && state.banksList.filter((x) => x.id == data.acc_id)[0] && state.banksList.filter((x) => x.id == data.acc_id)[0].bank_name ? state.banksList.filter((x) => x.id == data.acc_id)[0].bank_name : ""
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      selectedClient: state.clientDataForAutoComplete.filter((x) => x.clientId == data.supplier_id)[0],
      selectedMode: data?.transaction_mode,
      selectedBank: sBank,
      selectedAcc: data?.acc_id && `${data?.acc_id}`,
      updatedData: data,
      amount: data?.amount,
    }));
    let arr = [];
    let filtered = state.banksList.filter(
      (item) => item.bank_name === sBank
    );
    filtered.map((item) => arr.push(item.address));
    setState((prev) => ({ ...prev, accountsArr: filtered }));
  };
  const view = async (data = "") => {
    // console.log("data", data)
    setState((prev) => ({
      ...prev,
      viewPayment: !prev.viewPayment,
    }));
    if (data.id) {
      setState((prev) => ({
        ...prev,
        payments: data,
      }));
      const ledgerRes = await getSupplierLedgers()
      if (ledgerRes.success) {
        const filtered = ledgerRes.data.filter(x => x.id == data.supplier_id)
        if (filtered && filtered.length) {
          setState((prev) => ({
            ...prev,
            currentBalance: (parseInt(filtered[0].opening_balance) + parseInt(filtered[0].debit) - parseInt(filtered[0].credit)),
          }));
        }
      }
    }
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === "selectedBank") {
      let arr = [];
      let filtered = state.banksList.filter((item) => item.bank_name === value);
      filtered.map((item) => arr.push(item.address));
      setState((prev) => ({ ...prev, accountsArr: filtered }));
    }
    if (name === "selectedAcc") {
      // state.banksList.map(
      //   (item) =>
      //     item.account_number === value &&
      //     setState((prev) => ({ ...prev, accId: item.id }))
      // );
      setState((prev) => ({ ...prev, accId: value }))
    }
    // if (name === "selectedClient") {
    //   let filtered = state.clientData.filter(
    //     (item) => item.clientName === value
    //   );
    //   setState((prev) => ({
    //     ...prev,
    //     prevBalance: filtered[0].currentBalance,
    //   }));
    // }
  };
  const savePayment = async () => {
    if (!state.selectedClient || !state.selectedClient.clientId) {
      toast.error("Supplier must be selected...!")
      return
    }
    let filtered = state.clientData.filter((item) =>
      item.clientName === state.selectedClient.clientName
        ? state.selectedClient.clientName
        : state.selectedClient
    );
    let userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let obj = {
        // bank_id: state.selectedBank,
        supplier_id: state.selectedClient.clientId,
        transaction_mode: state.selectedMode,
        amount: state.amount,
        acc_id: state.accId,
        // total: state.prevBalance,
        // account_number: state.accNum,
        updated_by: userData.id
      };
      let res = await updateSupplierPayment(obj, state.updatedData.id);
      if (res.success) {
        toast.success("Payment updated successfully");
        handleModal();
        getPayments();
      }
      else {
        console.log(res)
        toast.error("Something went wrong");
      }
    } else {
      let obj = {
        date: new Date().toISOString().split('T')[0], //state.updatedData.date,
        // bank_id: state.selectedBank,
        supplier_id: state.selectedClient.clientId,
        transaction_mode: state.selectedMode,
        amount: state.amount,
        acc_id: state.accId,
        // total: state.prevBalance,
        // account_number: state.accNum,
        added_by: userData.id,
      };
      let res = await saveSupplierPayment(obj);
      if (res.success) {
        handleModal();
        await getPayments();
        await getSuppliersData();
        toast.success("Payment saved successfully");
      }
      else {
        console.log(res)
        toast.error("Something went wrong");
      }
    }
  }
  const updateData = (data) => {
    setState((prev) => ({ ...prev, paymentData: data }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Stack
      width={"98%"}
      sx={{
        padding: 1,
      }}
      ref={ref}
      id="content-to-print">
      <ClientInfo />
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Payments
        </Typography>
      </Stack>
      <Grid
        container
        style={{ marginBottom: "10px", marginTop: 8 }}
        spacing={1}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#05ae97",
              color: "#000",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              {" "}
              Payer:
            </Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {receivedBy}
            </Typography>
          </Stack>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#05ae97",
              color: "#000",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}> Received By:</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {state.clientData && state.clientData.length && state.clientData.filter((x) => x.id == state.payments.supplier_id)[0].name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#05ae97",
              color: "#000",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>
              Payment #
            </Typography>
          </Stack>

          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {state.payments && (state.payments.id.length == 1 ? "Payment#00" : state.payments.id.length == 2 ? "Payment#0" : "Payment#")}
              {state.payments.id}
            </Typography>
          </Stack>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack
            sx={{
              backgroundColor: "#05ae97",
              color: "#000",
              padding: 0.5,
              borderRadius: "10px",
            }}
            alignItems={"left"}>
            <Typography sx={{ fontWeight: "16px", mx: 2 }}>Date</Typography>
          </Stack>
          <Stack alignItems={"start"}>
            <Typography sx={{ marginTop: 2, mx: 2 }}>
              {" "}
              {state.payments.date}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Lay
        direction={"row"}
        spacing={2}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 4 }}
        alignItems={"center"}>
        <Typography>Transaction Mode:</Typography>
        <Typography>
          {state.payments.transaction_mode === "Bank"
            ? state.banksList && state.banksList.length > 0 && state.banksList.filter((x) => x.id == state.payments.acc_id)[0] && state.banksList.filter((x) => x.id == state.payments.acc_id)[0].bank_name ? state.banksList.filter((x) => x.id == state.payments.acc_id)[0].bank_name : ""
            : state.payments.transaction_mode}
        </Typography>
      </Lay>
      {state.payments.transaction_mode === "Bank" && (
        <Lay
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          sx={{ paddingLeft: 2, paddingRight: 2 }}
          alignItems={"center"}>
          <Typography>Account Title & Number :</Typography>
          <Typography>{accountInfo}</Typography>
        </Lay>
      )}
      <Lay
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
        alignItems={"center"}>
        <Typography>Amount:</Typography>
        <Typography>{state.payments.amount}</Typography>
      </Lay>
      <Lay
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ paddingLeft: 2, paddingRight: 2 }}
        alignItems={"center"}>
        <Typography>Current Balance:</Typography>
        <Typography>{state.currentBalance}</Typography>
      </Lay>
    </Stack>
  ));
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const handleOnSearch = (string) => {
    setState((prev) => ({ ...prev, selectedClient: string }));
  };
  const handleOnSelect = (item) => {
    setState((prev) => ({ ...prev, selectedClient: item.clientName }));
  };
  const handleClientChange = (e, value) => {
    if (value) {
      setState((prev) => ({
        ...prev,
        selectedClient: value,
      }));
    }
  };

  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        {!state.viewPayment ? (
          <>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: 700,
                }}></Typography>
              <Typography
                sx={{ color: "#000", fontSize: "24px", fontWeight: 700 }}>
                Payments
              </Typography>
              <Btn onClick={() => {
                setState((prev) => ({ ...prev, check: false }));
                handleModal()
              }}>
                <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new
                Payment
              </Btn>
            </Layout>
            <Stack sx={{ padding: "20px" }}>
              <DataTable
                editPayment={handleModal}
                view={view}
                data={state.paymentData}
                getData={getPayments}
                handleCheck={handleCheck}
                clientData={state.clientData}
              />
            </Stack>
          </>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Layout
              justifyContent="space-between"
              alignItems={"center"}
              direction={"row"}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: 700,
                  padding: 1,
                }}>
                {!smallScreen && "Payment Details"}
              </Typography>
              <Stack direction={"row"}>
                {/* <Btn onClick={view}>
                  <AppReg fontSize="small" sx={{ mx: 1 }} /> View All Payments
                </Btn> */}

                <ReactToPrint
                  trigger={() => (
                    <Btn>
                      <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                      {!smallScreen && "Print"}
                    </Btn>
                  )}
                  content={() => componentRef.current}
                />

                <Btn onClick={view}>
                  <Back sx={{ mx: 1 }} />
                  {!smallScreen && "Back"}
                </Btn>
              </Stack>
            </Layout>
            <ComponentToPrint ref={componentRef} />
          </Stack>
        )}
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <PaymentsIcon sx={{ color: "#05ae97" }} fontSize="large" />
            <Typo>Add new payment</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 2 }}>
            <Stack sx={{ mb: 2 }}>
              <Autocomplete
                options={state.clientDataForAutoComplete}
                fullWidth
                value={state.selectedClient}
                onChange={(e, value) => handleClientChange(e, value)}
                getOptionLabel={(option) => option?.clientName || option}
                sx={{ mb: 2 }}
                renderInput={(params) => (
                  <CustomField
                    {...params}
                    label="Select or Type supplier name"
                    variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Stack>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              select
              label="Select Transaction Mode"
              name="selectedMode"
              value={state.selectedMode}
              onChange={handleChange}
              sx={{ mb: 2 }}>
              {["Cash", "Bank"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
            {state.selectedMode === "Bank" && (
              <>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Bank Name"
                  name="selectedBank"
                  value={state.selectedBank}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.uniBanksArr.map((option) => (
                    <MenuItem key={option} value={option}>
                      {`${option}`}
                    </MenuItem>
                  ))}
                </CustomField>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Select Account"
                  name="selectedAcc"
                  value={state.selectedAcc}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.accountsArr.map((option, id) => (
                    <MenuItem
                      key={option.account_number}
                      value={option.id}>
                      {`${option.account_title} [${option.account_number}]`}
                    </MenuItem>
                  ))}
                </CustomField>
              </>
            )}
            <CustomField
              fullWidth
              id="outlined-select-currency"
              label="Enter Amount"
              name="amount"
              value={state.amount}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <Stack spacing={3} direction={"row"} sx={{ mt: 2 }}>
              <Button
                size="small"
                onClick={savePayment}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#05ae97",
                  color: "#000",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update payment" : " save payment"}
              </Button>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Stack>
  );
};

export default SupplierPayment;
