import { Stack, Typography } from "@mui/material"
import Logo from "../../assets/erorex-Logo.png";

const ClientInfo = () => {
  return (
    <Stack
      sx={{ mb: 2, mt: 1 }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"end"}
      spacing={3}>
      <img src={Logo} alt="logo" width={"550"} height={"120"} />

      <Stack sx={{mt: "-20px"}}>
        {/* <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          RZ Communication
        </Typography>
        <Typography sx={{ fontSize: "16px" }}>
          Elchi Plza Uromchi Plaza Back Street,
        </Typography>
        <Typography sx={{ fontSize: "16px" }}>
          Garden College Road Rawalpindi
        </Typography>
        <Typography sx={{ fontSize: "16px" }}>
          Ejaz Riaz : 0342-6888270
        </Typography> */}
      </Stack>
    </Stack>
  )
}

export default ClientInfo